import React from "react";
import Home from "./pages/home";
import NotFound from "./pages/not_found";
import Login from "./pages/auth/login";
import Logout from "./pages/auth/logout";
import UpdatePassword from "./pages/auth/update-password";
import ResetPassword from "./pages/auth/reset-password";
import Dashboard from "./pages/account/dashboard";
import Profile from "./pages/account/profile";
import Summary from "./pages/account/summary";
import PolicyList from "./pages/account/policy/policy-list";
import PolicyView from "./pages/account/policy/policy-view";
import PolicyEdit from "./pages/account/policy/policy-edit";
import PolicyCreate from "./pages/account/policy/policy-create";
import PolicyImport from "./pages/account/policy/policy-import";
import COCPortal from "./pages/account/policy/coc-portal";
import MemberList from "./pages/account/member/member-list";
import MemberEdit from "./pages/account/member/member-edit";
import ReportList from "./pages/account/report/report-list";

/**
 * @typedef {Object} Route
 * @property {string} path - The route path
 * @property {React.Component} element - The React component to be rendered
 */
const routes = [
    {path: "/", element: <Home/>},

    {path: "/login", element: <Login/>},
    {path: "/logout", element: <Logout/>},
    // {path: "/register", element: <Register/>},
    {path: "/update-password/:token", element: <UpdatePassword/>},
    {path: "/reset-password", element: <ResetPassword/>},
    {path: "/dashboard", element: <Dashboard/>},
    {path: "/profile", element: <Profile/>},
    {path: "/summary", element: <Summary/>},

    {path: "/policies", element: <PolicyList/>},
    {path: "/policy/view/:policy_uuid", element: <PolicyView/>},
    {path: "/policy/edit/:policy_uuid", element: <PolicyEdit/>},
    {path: "/policy/create", element: <PolicyCreate/>},
    {path: "/policy/import", element: <PolicyImport/>},
    {path: "/cocportal", element: <COCPortal/>},
    {path: "/member/edit/:integration_type/:member_uuid", element: <MemberEdit/>},

    {path: "/members", element: <MemberList/>},
    {path: "/reports", element: <ReportList/>},

    {
        path: "*",
        element: <NotFound/>,
    },
];

export default routes;