import React, {useState} from 'react';
import {useMutation, useQueryClient} from "react-query";
import {useNavigate} from "react-router-dom";
import {apollo} from "../../../../utils/apollo";
import {IMPORT_COC_FILE} from "../../../../utils/graphql";
import {ArrowDownTrayIcon, PhotoIcon, TrashIcon} from "@heroicons/react/24/solid";
import ButtonLink from "../../../../components/ButtonLink";
import Loading from "../../../../components/Loading";
import {cn} from "../../../../utils/style";
import {toast} from "react-toastify";
import {http} from "../../../../utils/http";
import {GAID_API_URL} from "../../../../utils/env";
import Button from "../../../../components/Button";


function COCPortalPolicyImportForm({ integration, fileUuid, setFileUuid }) {
    const queryClient = useQueryClient()
    const navigate = useNavigate();

    const [uploading, setUploading] = useState(false);
    const [errorLines, setErrorLines] = useState([]);
    const [file, setFile] = useState({
        realFileName: null, fileName: null, fileUrl: null, mimeType: null,
    });

    const importCOCFileMutation = useMutation({
        mutationFn: async () => {
            const {data} = await apollo.mutate({
                mutation: IMPORT_COC_FILE, variables: {
                    input: {
                        fileName: file?.fileName,
                        integrationUuid: integration?.integrationUuid || null,
                        fileUrl: file?.fileUrl || null,
                        mimeType: file?.mimeType || null,
                    }
                },
            });
            return data?.importCOCFile;
        }, onSuccess: (data) => {
            if (data.success && !data?.errorLines?.length) {
                toast.success('File under processing...')
                // queryClient.invalidateQueries(['policy_list']).then(() => {
                //     setTimeout(() => navigate("/policies"), 2000);
                // });
                setFileUuid(data?.fileImportUuid); 
            }
            if (data.success && !!data?.errorLines?.length) {
                toast.error('Some policies could not be processed successfully.')
                setErrorLines(data?.errorLines);
            }
        }, onError: (error) => {
            console.error(error)
        }

    });

    const resetFileField = () => {
        setFile({
            realFileName: null, fileName: null, fileUrl: null, mimeType: null,
        });
        setErrorLines([])
    }

    const handleFileUpload = async (event) => {
        setUploading(true);
        try {
            const selectedFile = event.target.files[0];
            if (!selectedFile) return;
            const formData = new FormData();
            formData.append('file', selectedFile);
            const response = await http.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const {fileName, fileUrl, mimeType} = response?.data;
            if (fileName) {
                setFile({
                    fileName,
                    fileUrl,
                    mimeType
                });
            }
        } catch (error) {
            console.error(error);
        }
        setUploading(false);
    }


    return (<div>
        <div className="flex flex-col md:flex-row gap-4 my-4 border border-sky-200 bg-sky-50 p-4 rounded">
            <div className="text-sm text-sky-800">
                Download the member data template, fill the details and re-upload it. Please do not change the name and
                format of the downloaded file while uploading it. Please use latest XLSX template while uploading the
                data
            </div>
            <div className="shrink-0">
                <ButtonLink href={`${process.env.REACT_APP_GAID_API_BASE_URL}/coc_template.xlsx`} target="_blank" secondary={true}>Download Template.xlsx</ButtonLink>
            </div>
        </div>

        <div className={cn("sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-4 sm:grid")}>
            <label
                className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                CSV/XLSX File <span className="text-red-500">*</span>
            </label>
            <div className="relative p-0.5 mt-2 sm:col-span-2 sm:mt-0 overflow-hidden">
                {uploading && <div
                    className="backdrop-blur-sm z-40 w-full h-full absolute top-0 left-0 bg-white/20 flex justify-center items-center">
                    <Loading message="Uploading ..."/>
                </div>}

                {file?.fileUrl && <div>
                    <div className="mb-1 overflow-x-auto">
                        <p className="text-sm text-gray-500 font-mono leading-6 tracking-tight">
                            {file?.fileName || `${GAID_API_URL}${file?.fileUrl}`}
                        </p>
                    </div>
                    <div className="flex gap-3 items-center">
                        <ButtonLink href={`${GAID_API_URL}${file?.fileUrl}`} target="_blank"
                                    className="flex items-center justify-center gap-2">
                            <ArrowDownTrayIcon className="h-4 w-4 text-white"/>
                            <span>Download</span>
                        </ButtonLink>
                        <Button secondary={true} onClick={resetFileField}
                                className="flex items-center justify-center gap-2">
                            <TrashIcon className="h-4 w-4 text-white"/>
                            <span>Clear</span>
                        </Button>
                    </div>
                </div>}

                {!file?.fileName && <div
                    className="flex max-w-2xl justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10 bg-white">
                    <div className="text-center">
                        <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true"/>
                        <div className="mt-4 flex text-sm leading-6 text-gray-600">
                            <label
                                htmlFor="policy-bulk-upload-file"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text--blue-500 focus-within:outline-none focus-within:ring-2 focus-within:ring--primary-500 focus-within:ring-offset-2 hover:text-primary-500"
                            >
                                <span>Upload a file</span>
                                <input id="policy-bulk-upload-file" name="policy-bulk-upload-file"
                                       onChange={handleFileUpload}
                                       accept=".csv,.xlsx"
                                       type="file" className="sr-only"/>
                            </label>
                            <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs leading-5 text-gray-600">CSV, XLSX up to 10MB</p>
                    </div>
                </div>}
            </div>
        </div>

        {!!errorLines?.length && <div className="mt-6 flex flex-wrap items-center gap-4">
            <label htmlFor="policy-bulk-errors"
                   className="block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5">
                Error Lines
            </label>
            <textarea name="policy-bulk-errors" id="policy-bulk-errors" cols="30" rows="10"
                      className="block disabled:opacity-60 disabled:bg-gray-200 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-500 sm:text-sm sm:leading-6"
                      value={errorLines.join("\n")} readOnly={true}></textarea>
        </div>}
        <div className="mt-6 flex flex-wrap items-center gap-4">
            <Button disabled={importCOCFileMutation.isLoading || !file?.fileName} type="button"
                    onClick={() => {
                        setErrorLines([]);
                        importCOCFileMutation.mutate();
                    }}>
                <span className="flex items-center gap-2">Process File</span>
            </Button>
        </div>
    </div>);
}

export default COCPortalPolicyImportForm;