import React, { useMemo, useState } from 'react';
import { useQuery, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { apollo } from "../../../../utils/apollo";
import { GET_COC_IMPORT_LIST } from "../../../../utils/graphql";
import { GET_COC_IMPORT_LIST_FULL } from "../../../../utils/graphql";
import PaginationFull from "../../../../components/PaginationFull";
import TextInput from "../../../../components/TextInput";

function COCImportView({ fileUuid }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState(""); // State to hold the search term

    const fetchFileImportProcessing = useQuery({
        queryKey: ['coc_import_processing_view_list', { page, fileUuid, searchTerm }],
        queryFn: async () => {
            const { data } = await apollo.query({
                query: GET_COC_IMPORT_LIST,
                variables: {
                    page,
                    first: 15,
                    filter: {
                        fileUuid: fileUuid || null,
                        searchTerm: searchTerm || null, // Use searchTerm filter
                    },
                    fetchAll: false // Full data for export
                },
            });
            return data?.getCOCImportList;
        },
        keepPreviousData: false,
    });

    const fileImports = useMemo(() => fetchFileImportProcessing?.isSuccess ? fetchFileImportProcessing?.data : null, [fetchFileImportProcessing]);

    // Function to export data to CSV
    const exportToCSV = async () => {
        const headers = [
            "ShafafiyaReferenceNo", "EmiratesId", "UId", 
            "TotalPenalty", "Penalty", "NoInsuranceDays", 
            "ValidityDate", "ErrorCode"
        ];

        // Fetch the full data for export
        const { data } = await apollo.query({
            query: GET_COC_IMPORT_LIST_FULL,
        });

        // Ensure the response has data
        const fileImports = data?.getCOCImportListFull || [];

        // Prepare data rows by mapping over the correct data
        const rows = fileImports.map(fileImport => [
            fileImport.shafafiyaReferenceNo,
            fileImport.eid,
            fileImport.uid,
            fileImport.totalPenalty,
            fileImport.penalty,
            fileImport.nonInsuranceDays,
            fileImport.validityDate,
            fileImport.errorCode || "" // Handle null errorCode
        ]);

        // Add headers to the data
        const csvContent = [
            headers.join(','), // Join the headers with commas
            ...rows.map(row => row.join(',')) // Join each row's values with commas
        ].join('\n'); // Join each line with a newline

        // Create a Blob with CSV content and trigger a download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = "coc_export.csv"; // Specify the name of the CSV file
        link.click();
    };

    return (
        <div className="mt-6">
            <div className="mt-4 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="my-4 shadow-md bg-white border rounded-lg px-4 py-4 grid grid-cols-1 md:grid-cols-12 gap-4">
                            <div className="col-span-3">
                                <TextInput
                                    name="search-term"
                                    label="Search by Emirates Id, Shafafiya Ref, or UId"
                                    id="search-term"
                                    get={searchTerm}
                                    set={setSearchTerm}
                                />
                            </div>
                            <div className="col-span-3 flex mt-7 justify-end"> {/* Aligned the button to the right */}
                                <button
                                    onClick={exportToCSV}
                                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
                                >
                                    Export to CSV
                                </button>
                            </div>
                        </div>
                        <table className="min-w-full divide-y divide-gray-300">
                            <thead>
                                <tr>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        ShafafiyaReferenceNo
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        EmiratesId
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        UId
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        TotalPenalty
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        Penalty
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        NoInsuranceDays
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        ValidityDate
                                    </th>
                                    <th className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                        ErrorCode
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {fileImports?.data?.map((fileImport) => (
                                    <tr key={fileImport.shafafiyaReferenceNo}>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.shafafiyaReferenceNo}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.eid}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.uid}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.totalPenalty}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.penalty}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.nonInsuranceDays}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.validityDate}
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                            {fileImport.errorCode}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {fileImports?.data && !fileImports?.data?.length &&
                            <div className="flex items-center justify-center h-40 text-gray-500 font-light text-xl">
                                No file imports found
                            </div>}
                    </div>
                </div>
            </div>
            <PaginationFull paginator={fileImports?.paginatorInfo} get={page} set={setPage} />
        </div>
    );
}

export default COCImportView;
